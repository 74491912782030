/**
 * 
 */
ready(function () {
  var videoTags = document.getElementsByClassName('ftb-video__player');
  var players = {};
  for (var int = 0; int < videoTags.length; int += 1) {
    var currentId = videoTags[int].id;
    var videoName = currentId.replace('videoplayer_', '');
    if (document.getElementById(currentId)) {
      videojs.options.flash.swf = 'video-js.swf';
      players[videoName] = videojs(currentId);
      players[videoName].block_slider_update = false;
      players[videoName].videoName = videoName;
      // 
      document.getElementById('position-slider' + videoName).onmousedown = function () {
        videoName = this.id.replace('position-slider', '');
        players[videoName].block_slider_update = true
      };
      document.getElementById('position-slider' + videoName).onmouseup = function () {
        videoName = this.id.replace('position-slider', '');
        players[videoName].block_slider_update = false
      };
      document.getElementById('position-slider' + videoName).onchange = function (event) {
        event.preventDefault();
        videoName = this.id.replace('position-slider', '');
        players[videoName].pause();
        players[videoName].currentTime(players[videoName].duration() * this.value / 100);
        players[videoName].play()
      };
      document.getElementById('player-play' + videoName).onclick = function (event) {
        event.preventDefault();
        videoName = this.id.replace('player-play', '');
        players[videoName].play()
      };
      document.getElementById('player-pause' + videoName).onclick = function (event) {
        event.preventDefault();
        videoName = this.id.replace('player-pause', '');
        players[videoName].pause()
      };
      document.getElementById('player-restart' + videoName).onclick = function (event) {
        event.preventDefault();
        videoName = this.id.replace('player-restart', '');
        players[videoName].pause();
        players[videoName].currentTime(0);
        players[videoName].play()
      };
      document.getElementById('player-back' + videoName).onclick = function (event) {
        event.preventDefault();
        videoName = this.id.replace('player-back', '');
        var currentTime = parseInt(players[videoName].currentTime());
        if (currentTime > 5) {
          players[videoName].pause();
          players[videoName].currentTime(currentTime - 5);
          players[videoName].play()
        } else {
          players[videoName].pause();
          players[videoName].currentTime(0);
          players[videoName].play()
        }
      };
      document.getElementById('player-full' + videoName).onclick = function (event) {
        event.preventDefault();
        videoName = this.id.replace('player-full', '');
        players[videoName].requestFullscreen()
      };
      document.getElementById('player-captions' + videoName).onclick = function (event) {
        event.preventDefault();
        //				var source = event.target || event.srcElement;
        videoName = this.id.replace('player-captions', '');
        if (this.textContent == 'Untertitel an') {
          //					var checkCuesReady = function(track){
          //						  // stop worrying if the tech has been disposed
          //						  if (!track) {
          //						    return;
          //						  }
          //
          //						  // check if cues are loaded
          //						  if (track.cues != null) {
          //
          //							  formatCues(textTrack);
          //							   
          //						  } else {
          //						    // wait longer
          //						    this.setTimeout(function(){
          //						    checkCuesReady(track);
          //						    }, 50);
          //						  }
          //						};
          textTrack = players[videoName].textTracks()[0];
          textTrack.mode = 'showing';
          //					checkCuesReady(textTrack);
          //					var capsSet = document.getElementsByClassName('vjs-caption-settings');
          //					capsSet.className = '';
          //					capsSet.className = 'vjs-caption-settings';
          //					players[videoName].textTrackSettings.init();
          //					players[videoName].textTracks()[0].oncuechange = function (){
          //						  // 'this' is a textTrack
          //						  var cue = this.activeCues[0]; // assuming there is only one active cue
          //						  cue.line = captions_vertical_line; // 1 - 16
          //					}
          //					align: 'middle'
          //					endTime: 159.116
          //					id: '61'
          //					line: 'auto'
          //					onenter: null
          //					onexit: null
          //					pauseOnExit: false
          //					position: 'auto'
          //					size: 100
          //					snapToLines: true
          //					startTime: 158.1
          //					text: 'Barney's engaged?!'
          //					track: TextTrack
          //					vertical: ''
          this.textContent = 'Untertitel aus'
        } else {
          players[videoName].textTracks()[0].mode = 'disabled';
          this.textContent = 'Untertitel an'
        }
      };
      //			document.getElementById('font-size').onchange = (function(event) {
      //				event.preventDefault();
      //				
      //				if (document.styleSheets[0].addRule) {
      //					document.styleSheets[0].addRule('::cue','font-size: ' + this.value);
      //			      } else {
      //					addCssRule('::cue', '{ font-size: ' + this.value + ';}')
      //					console.log('::cue { font-size: ' + this.value + ';}');
      //					style.sheet.insertRule('cue { font-size: "' + this.value + "';}',0);
      //			      }
      //				var values = new Object();
      //				values.edgeStyle = '';
      //			    values.fontFamily = '';
      //			    values.color = '';
      //			    values.textOpacity = '';
      //			    values.backgroundColor = '';
      //			    values.backgroundOpacity = '';
      //			    values.windowColor = '';
      //			    values.windowOpacity = '';
      //			    values.fontPercent = parseFloat(this.value);
      //			    var cue = document.querySelector('::cue');
      //				players[videoName].textTrackSettings.setValues(values);
      //				players[videoName].textTrackSettings.saveSettings();
      //				players[videoName].textTrackSettings.updateDisplay();
      //				players[videoName].textTrackDisplay.updateDisplay();
      //			});
      //			document.getElementById('position').onchange = (function(event) {
      //				event.preventDefault();
      //				captions_vertical_line = parseInt(this.value);
      //			});
      players[videoName].ready(function () {
        var duration = '0:00';
        this.on('timeupdate', function () {
          document.getElementById('current-time' + this.videoName).innerHTML = formatTime(players[this.videoName].currentTime(), duration) + ' / ' + formatTime(players[this.videoName].duration(), players[this.videoName].duration());
          if (!players[this.videoName].block_slider_update) {
            document.getElementById('position-slider' + this.videoName).value = Math.floor(players[this.videoName].currentTime() / players[this.videoName].duration() * 100);
            document.getElementById('position-slider' + this.videoName).setAttribute('value', Math.floor(players[this.videoName].currentTime() / players[this.videoName].duration() * 100));
            document.getElementById('position-slider' + this.videoName).setAttribute('aria-valuenow', Math.floor(players[this.videoName].currentTime() / players[this.videoName].duration() * 100))
          }
        });
        this.on('durationchange', function () {
          var duration = players[this.videoName].duration();
          document.getElementById('current-time' + this.videoName).innerHTML = formatTime(players[this.videoName].currentTime(), duration) + ' / ' + formatTime(players[this.videoName].duration(), duration);
          if (!players[this.videoName].block_slider_update) {
            document.getElementById('position-slider' + this.videoName).value = Math.floor(players[this.videoName].currentTime() / players[this.videoName].duration() * 100);
            document.getElementById('position-slider' + this.videoName).setAttribute('value', Math.floor(players[this.videoName].currentTime() / players[this.videoName].duration() * 100));
            document.getElementById('position-slider' + this.videoName).setAttribute('aria-valuenow', Math.floor(players[this.videoName].currentTime() / players[this.videoName].duration() * 100))
          }
        })
      })
    }
  }
  function formatTime(seconds, guide) {
    // Default to using seconds as guide
    guide = guide || seconds;
    var s = Math.floor(seconds % 60), m = Math.floor(seconds / 60 % 60), h = Math.floor(seconds / 3600), gm = Math.floor(guide / 60 % 60), gh = Math.floor(guide / 3600);
    // handle invalid times
    if (isNaN(seconds) || seconds === Infinity) {
      // '-' is false for all relational operators (e.g. <, >=) so this setting
      // will add the minimum number of fields specified by the guide
      h = m = s = '-'
    }
    // Check if we need to show hours
    h = h > 0 || gh > 0 ? h + ':' : '';
    // If hours are showing, we may need to add a leading zero.
    // Always show at least one digit of minutes.
    m = ((h || gm >= 10) && m < 10 ? '0' + m : m) + ':';
    // Check if leading zero is need for seconds
    s = s < 10 ? '0' + s : s;
    return h + m + s
  }
})  /*
formatCues = function(track) {
	
	
	
    overrides = {
    	      'backgroundOpacity': '1',
    	      'textOpacity': '1',
    	      'windowOpacity': '1',
    	      'edgeStyle': 'dropshadow',
    	      'fontFamily': 'script',
    	      'color': '#000',
    	      'backgroundColor': '#FFF',
    	      'windowColor': '#CCC',
    	      'fontPercent': '4.0'
    	    };
	
	// Add cue HTML to display
	var constructColor = function(color, opacity) {
	  return 'rgba(' +
	    // color looks like '#f0e'
	    parseInt(color[1] + color[1], 16) + ',' +
	    parseInt(color[2] + color[2], 16) + ',' +
	    parseInt(color[3] + color[3], 16) + ',' +
	    opacity + ')';
	};
	var darkGray = '#222';
	var lightGray = '#ccc';
	var fontMap = {
	  monospace:             'monospace',
	  sansSerif:             'sans-serif',
	  serif:                 'serif',
	  monospaceSansSerif:    '"Andale Mono", "Lucida Console", monospace',
	  monospaceSerif:        '"Courier New", monospace',
	  proportionalSansSerif: 'sans-serif',
	  proportionalSerif:     'serif',
	  casual:                '"Comic Sans MS", Impact, fantasy',
	  script:                '"Monotype Corsiva", cursive',
	  smallcaps:             '"Andale Mono", "Lucida Console", monospace, sans-serif'
	};
	
	cues = [];
	
	for (i = 0; i < track.cues.length; i++) {
		cues.push(track.cues[i]);
	}

    var styleBox, cue;

    for (var i = 0; i < cues.length; i++) {
      cue = cues[i];

      // Compute the intial position and styles of the cue div.
      styleBox = new CueStyleBox(window, cue, styleOptions);
      paddedOverlay.appendChild(styleBox.div);

      // Move the cue div to it's correct line position.
      moveBoxToLinePosition(window, styleBox, containerBox, boxPositions);

      // Remember the computed div so that we don't have to recompute it later
      // if we don't have too.
      cue.displayState = styleBox.div;

      boxPositions.push(BoxPosition.getSimpleBoxPosition(styleBox));
    }
    
	window['WebVTT']['processCues'](window, track.cues, this.el_);

	i = cues.length;
	while (i--) {
		cueDiv = cues[i].displayState;
		if (overrides.color) {
			cueDiv.firstChild.style.color = overrides.color;
		}
		if (overrides.textOpacity) {
			tryUpdateStyle(cueDiv.firstChild, 'color', constructColor(
					overrides.color || '#fff', overrides.textOpacity));
		}
		if (overrides.backgroundColor) {
			cueDiv.firstChild.style.backgroundColor = overrides.backgroundColor;
		}
		if (overrides.backgroundOpacity) {
			tryUpdateStyle(cueDiv.firstChild, 'backgroundColor',
					constructColor(overrides.backgroundColor || '#000',
							overrides.backgroundOpacity));
		}
		if (overrides.windowColor) {
			if (overrides.windowOpacity) {
				tryUpdateStyle(cueDiv, 'backgroundColor', constructColor(
						overrides.windowColor, overrides.windowOpacity));
			} else {
				cueDiv.style.backgroundColor = overrides.windowColor;
			}
		}
		if (overrides.edgeStyle) {
			if (overrides.edgeStyle === 'dropshadow') {
				cueDiv.firstChild.style.textShadow = '2px 2px 3px ' + darkGray
						+ ', 2px 2px 4px ' + darkGray + ', 2px 2px 5px '
						+ darkGray;
			} else if (overrides.edgeStyle === 'raised') {
				cueDiv.firstChild.style.textShadow = '1px 1px ' + darkGray
						+ ', 2px 2px ' + darkGray + ', 3px 3px ' + darkGray;
			} else if (overrides.edgeStyle === 'depressed') {
				cueDiv.firstChild.style.textShadow = '1px 1px ' + lightGray
						+ ', 0 1px ' + lightGray + ', -1px -1px ' + darkGray
						+ ', 0 -1px ' + darkGray;
			} else if (overrides.edgeStyle === 'uniform') {
				cueDiv.firstChild.style.textShadow = '0 0 4px ' + darkGray
						+ ', 0 0 4px ' + darkGray + ', 0 0 4px ' + darkGray
						+ ', 0 0 4px ' + darkGray;
			}
		}
		if (overrides.fontPercent && overrides.fontPercent !== 1) {
			fontSize = window.parseFloat(cueDiv.style.fontSize);
			cueDiv.style.fontSize = (fontSize * overrides.fontPercent) + 'px';
			cueDiv.style.height = 'auto';
			cueDiv.style.top = 'auto';
			cueDiv.style.bottom = '2px';
		}
		if (overrides.fontFamily && overrides.fontFamily !== 'default') {
			if (overrides.fontFamily === 'small-caps') {
				cueDiv.firstChild.style.fontVariant = 'small-caps';
			} else {
				cueDiv.firstChild.style.fontFamily = fontMap[overrides.fontFamily];
			}
		}
	}
};
*/